import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import * as XLSX from 'xlsx';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { AccountService, AlertService } from '../../_services';
import { User } from '../../_models';
import { DealerSummaryService } from './dealer-summary.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-hero-dealer-summary',
  standalone: true,
  imports: [ReactiveFormsModule, AgGridAngular, CommonModule],
  templateUrl: './hero-dealer-summary.component.html',
  styleUrls: ['./hero-dealer-summary.component.less']
})
export class HeroDealerSummaryComponent implements OnInit {
  private gridApi!: GridApi;
  user: User;
  uploadForm: FormGroup;
  searchForm: FormGroup;
  isUploadFormShow = false;
  file: File | null = null;
  gridRowData: any[] = [];
  columnDefs: ColDef[] = [
    { headerName: "Serial No", valueGetter: (params) => params.node.rowIndex + 1 },
    { headerName: 'Dealer Name', field: 'DealerName' },
    { headerName: 'Dealer City', field: 'DealerCity' },
    { headerName: 'Number of Policies', field: 'NumberOfPolicies' }
  ];
  defaultColDef = {
    resizable: true, 
    sortable: true,
    filter: true
  };
  months = [
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' }
  ];
  years: number[] = [];
  currentMonth: number;
  currentYear: number;
  availableMonths: { value: number; name: string; }[];
  excelData: unknown[];

  constructor(private fb: FormBuilder, private accountService: AccountService
    ,private alertService: AlertService,
    private dealerSummaryService: DealerSummaryService,
  ) {
    this.accountService.user.subscribe(x => this.user = x);
  }

  ngOnInit() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;
    this.currentYear = currentDate.getFullYear();

    for (let year = 2024; year <= this.currentYear; year++) {
      this.years.push(year);
    }

    this.uploadForm = this.fb.group({
      month: [this.currentMonth, Validators.required],
      year: [this.currentYear, Validators.required]
    });

    this.searchForm = this.fb.group({
      month: [this.currentMonth, Validators.required],
      year: [this.currentYear, Validators.required]
    });

    this.uploadForm.get('year')?.valueChanges.subscribe(year => {
      this.updateMonthOptions(year, 'uploadForm');
    });

    this.searchForm.get('year')?.valueChanges.subscribe(year => {
      this.updateMonthOptions(year, 'searchForm');
    });

    this.updateMonthOptions(this.currentYear, 'uploadForm');
    this.updateMonthOptions(this.currentYear, 'searchForm');
  }

  updateMonthOptions(year: number, formType: string) {
    console.log('Year:', year,this.currentYear);
    const form = formType === 'uploadForm' ? this.uploadForm : this.searchForm;
    const monthControl = form.get('month');
    if (year == this.currentYear) {
      this.availableMonths = this.months.filter(month => month.value <= this.currentMonth + 1);
      console.log('Available Months:', this.availableMonths);
      monthControl?.setValue(this.currentMonth);
      monthControl?.setValidators([Validators.required]);
      monthControl?.updateValueAndValidity();
    } else {
      this.availableMonths = this.months;
      console.log('Available Months:', this.availableMonths);
      monthControl?.setValidators([Validators.required]);
      monthControl?.updateValueAndValidity();
    }
  }

  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    this.file = target.files[0];
  }

  async onFileUpload() {
    if (!this.file) return;
    const data = await this.file.arrayBuffer();
    const workbook = XLSX.read(data);
    this.excelData = (
      XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
        raw: false,
        header: 2,
      }) as {
        DealerName: string;
        DealerCity: string;
        NumberOfPolicies: number;
      }[]
    ).filter((a) => a.DealerName);

    this.dealerSummaryService
      .updateDealerSummary({
        month: this.uploadForm.get('month').value,
        year: this.uploadForm.get('year').value,
        dealerSummary: this.excelData,
      })
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.gridRowData = this.excelData;
          this.searchForm.patchValue({
            month: this.uploadForm.get('month').value,
            year: this.uploadForm.get('year').value,
          });
          this.alertService.success('Dealer Summary uploaded successfully', {
            keepAfterRouteChange: true,
          });
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    
    // const reader: FileReader = new FileReader();
    // reader.onload = (e: any) => {
    //   const bstr: string = e.target.result;
    //   const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
    //   const wsname: string = wb.SheetNames[0];
    //   const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    //   const data = <any[]>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
    //   this.rowData = data.slice(1).map(row => ({
    //     dealerName: row[0],
    //     dealerCity: row[1],
    //     numberOfPolicies: row[2]
    //   }));
    // };
    // reader.readAsBinaryString(this.file);
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }
  onSearch() {
    const month = this.searchForm.get('month')?.value;
    const year = this.searchForm.get('year')?.value;
    // Implement search functionality based on month and year
    // For now, just log the search parameters
    this.dealerSummaryService.getDealerSummary(month, year).pipe(first())
    .subscribe({
      next: (data) => {
        if (data.length === 0) {
          this.alertService.error('No data found');
          this.gridRowData = [];
          return;
        }
        this.gridRowData = data[0].dealerSummary;
        this.alertService.success('Dealer Summary fetched successfully', {
          keepAfterRouteChange: true,
        });
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });

  }
  uploadFormToggle(){
    this.isUploadFormShow = !this.isUploadFormShow;
  }
  exportToExcel() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'Dealer Summary Report - ' + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString(),
      //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.gridApi.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }
}