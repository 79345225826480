<div class="p-4">
    <div class="row">
        <div class="form-group">

    <button [disabled]="getloading()"  (click)="pdfPrint()" class="btn btn-sm btn-success mb-2">Download Certificate
        <span *ngIf="getloading()" class="spinner-border spinner-border-sm mr-1"></span>
    </button ><a>&nbsp;&nbsp;</a>
    </div>
    </div>
    <div class="row" *ngIf="(certificate.showSummaryPage || certificate.onePager)">
        <iframe *ngIf="!getloading()" id="myFrame" [src]="pdfSrc"  style="width: 80%; height: 80vh;"></iframe>
    </div>

    </div>
<div id="combinedPage" *ngIf="!(certificate.showSummaryPage || certificate.onePager)">
            <div id="printPage" style="width:1122px;height: 1584px; overflow:auto;" *ngIf="selectedPrintInstruction.printPage">
                <router-outlet></router-outlet>
    </div>
<div style="margin-top: 10px;" *ngIf= printCpaInsurancePolicy>
            <div id="printPage2" style="width: 1122px;height: 1584px; overflow: auto;" *ngIf="selectedPrintInstruction.printPage2">
                <app-print-new-india-policy ></app-print-new-india-policy>
            </div>
            <div id="printPage3" style="width: 1122px;height: 1584px; overflow: auto;" *ngIf="selectedPrintInstruction.printPage3">
                <app-print-accident-policy ></app-print-accident-policy>
            </div>
            <div id="printPageFinance" style="width: 1122px;height: 1584px; overflow: auto;" *ngIf="selectedPrintInstruction.printPageFinance">
                <app-policy-finance ></app-policy-finance>
            </div>
            <div id="printPageCarCPA" style="width: 1122px;height: 1584px; overflow: auto;" *ngIf="selectedPrintInstruction.printPageCarCPA">
                <app-print-new-india-car-policy ></app-print-new-india-car-policy>
            </div>
            <div id="printPageIfcoTokio" style="width: 1122px;height: 1584px; overflow: auto;" *ngIf="selectedPrintInstruction.printPageIfcoTokio">
                <app-iffco-tokyo-cpa-policy ></app-iffco-tokyo-cpa-policy>
            </div>
            <div id="printPageGeneralCPA" style="width: 1122px;height: 1584px; overflow: auto;" *ngIf="selectedPrintInstruction.printPageGeneralCPA">
                <app-general-cpa-policy ></app-general-cpa-policy>
            </div>
    </div>
</div>