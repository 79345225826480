
<div>
  <h2>Search Dealer Summary</h2>
  <form [formGroup]="searchForm"  class="mb-4">
    <div class="row mb-3">
      <div class="col">
        <label for="searchMonth" class="form-label">Month:</label>
        <select id="searchMonth" formControlName="month" class="form-select">
          <option *ngFor="let month of availableMonths" [value]="month.value">{{ month.name }}</option>
        </select>
      </div>
      <div class="col">
        <label for="searchYear" class="form-label">Year:</label>
        <select id="searchYear" formControlName="year" class="form-select">
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
      </div>
    </div>
    <button type="submit" (click)="onSearch()" class="btn btn-primary">Search</button><a>&nbsp;&nbsp;</a>
    <button (click)="exportToExcel()" class="btn btn-primary" *ngIf="gridRowData.length > 0 && user.role === 'admin' ">
      <!-- <span  class="spinner-border spinner-border-sm mr-1"></span> -->
      Export to Excel
  </button>
  </form>
</div>

<div class="ag-theme-alpine" style="height: 400px; width: 100%;">
  <ag-grid-angular
  [rowData]="gridRowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [paginationPageSize]="100"
  (gridReady)="onGridReady($event)"
    style="width: 100%; height: 100%;">
  </ag-grid-angular>
</div>

<div *ngIf="user.role === 'admin' && !(user.specialRoleAccess && user.specialRoleAccess.includes('HeroReport'))">
  <nav aria-label="breadcrumb" (click)="uploadFormToggle()" style="cursor: pointer;margin-top: 10px;">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item active" aria-current="page" >
        <i class="fa fa-plus" style="margin-right: 10px; " *ngIf="!isUploadFormShow"></i>
        <i class="fa fa-minus" style="margin-right: 10px; " *ngIf="isUploadFormShow"></i>
        <p class="h5"> Upload Excel File</p></li>
    </ol>
  </nav>
  <div *ngIf="isUploadFormShow">
    <div class="row">
    <div class="col" >
        <h4 >Download <strong>Upload Template</strong> from  <a target="_self" href="../../../assets/HeroSummaryReport.xlsx" download="Hero Summary Report.xlsx"><span>here</span></a>
    </h4>
    </div>
    </div>
    <form [formGroup]="uploadForm" (ngSubmit)="onFileUpload()" class="mb-4">
      <div class="row mb-3">
        <div class="col">
          <label for="month" class="form-label">Month:</label>
          <select id="month" formControlName="month" class="form-select">
            <option *ngFor="let month of availableMonths" [value]="month.value">{{ month.name }}</option>
          </select>
        </div>
        <div class="col">
          <label for="year" class="form-label">Year:</label>
          <select id="year" formControlName="year" class="form-select">
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label for="file" class="form-label">Select File:</label>
        <input type="file" id="file" (change)="onFileChange($event)" class="form-control" />
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!uploadForm.valid || !file">Upload</button>
    </form>
  </div>
</div>
