export class PrintInstruction {
    policyType = 'default';
    printPage = false;
    printPage2 = false;
    printPage3 = false;
    printPageFinance = false;
    printPageCarCPA = false;
    printOnlyFirstPage = false;
    printPageIfcoTokio = false;
    printPageGeneralCPA = false;
    maxAgeAllowed = 70;
    minAgeAllowed = 18;
    policyCompanyImage="assets/NewIndiaLogo.png";
    policyCompany ="THE NEW INDIA ASSURANCE CO. LTD";
    aggregatePolicyType?: string = "Others";
    public constructor(init?: Partial<PrintInstruction>) {
        Object.assign(this, init);
}
}