import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { AccountService, AlertService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.less']
})
export class FinanceComponent implements OnInit {

  certificate:Certificate;
  showGstAmount=true;
  currentDate = new Date();
  user: User;

  constructor(private certificateService: CertificateService, private dealerService: DealerService, private accountService: AccountService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.certificate = new Certificate(this.certificateService.PrintCertificate)
    this.user = this.accountService.userValue;
//     if (this.user.specialRoleAccess.includes('ViewNetAmountGST')) {
//       this.dealerService.getByDealerId(this.certificate.dealerId)
//         .pipe(first())
//         .subscribe({
//           next: (x) => {
//             if (x.dealerGst && x.dealerGst == "Net")
//             {
//               this.certificate.planAmount = this.certificate.NetPlanAmount;
//               if (Number(this.certificate.IgstAmount) > 0)
//                 {
//                   this.certificate.IgstAmount = this.certificate.NetPlanGSTAmount;
//                 }
//               else
//               {
//                 this.certificate.CgstAmount = this.certificate.NetPlanGSTAmount / 2;
//                 this.certificate.SgstAmount = this.certificate.NetPlanGSTAmount / 2;
//               }
// this.certificate.planTotalAmount = Math.round(this.certificate.NetPlanTotalAmount);
//             }
//         },
//           error: error => {
//             this.alertService.error(error)
//           }
//         })
//     }
    this.showGstAmount = this.certificate.gstApplicable ? this.certificate.gstApplicable =="N" ? false : true : true; 
    this.showGstAmount = this.showGstAmount == false? false : this.certificate.gstShow ? this.certificate.gstShow =="N" ? false : true : true; 
  }

}
