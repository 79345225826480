import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerSummaryService {

  constructor(private http: HttpClient) { }
  
  updateDealerSummary(dealerSummary: any){
    return this.http.post<any>(`${environment.apiUrl}/heroDealerSummary/updateDealerSummary`, dealerSummary);
  }
  getDealerSummary(month: number, year: number){
    return this.http.get<any>(`${environment.apiUrl}/heroDealerSummary/getDealerSummary/${month}/${year}`);
  }
}
