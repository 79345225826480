import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { PrintInstruction } from '@app/_models/printInstruction';
import { ReportSearch } from '@app/_models/reportSearch';
import { AlertService, AccountService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { first } from 'rxjs';

@Component({
  selector: 'app-dealer-summary-report',
  standalone: true,
  imports: [AgGridAngular,ReactiveFormsModule,NgbModule,CommonModule],
  templateUrl: './dealer-summary-report.component.html',
  styleUrl: './dealer-summary-report.component.less'
})
export class DealerSummaryReportComponent implements OnInit {
  private gridApi!: GridApi;
  certificateReportForm: UntypedFormGroup;
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridRowData: any[]=[];
  loading = false;
  gridReady = true;
  booleanArray = ["","Yes","No"];
  reportSearch: ReportSearch;
  selectedPrintInstruction:PrintInstruction[];
  issueDateFromSearch: string;
  issueDateToSearch: string;
  reportCertificateData: any[];
  OutputArray = [];
  currentDate = new Date();
  stateRegexp : RegExp;
  idRegexp: RegExp;
  dealerData:Dealer[] = [];
  user: User;
  columnDefs:ColDef[] = [
    { headerName: "Serial No", valueGetter: (params) => params.node.rowIndex + 1 },
    { headerName: "Dealer Id", field: "dealerId" }
    , { headerName: "Dealer Name", field: "dealerName" }
    , { headerName: "Dealer City", field: "dealerCity"}
    , { headerName: "Dealer State", field: "dealerState"}
    , { headerName: "Plan Type", field: "dealerPlanType" }
    ,{headerName: "Make", field: "vehicleMake"}
    , { headerName:"Type", field: "type"}
    , { headerName: "Total", field: "totalPolicyNumber"}
];
  defaultColDef = {
    resizable: true, 
    sortable: true,
    filter: true
  };
  aggregatePoilyTypes: any[] = [];
  resultTotal: number;
  lineTotal: number;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService,
    private configDataService: ConfigDataService

  ) { }
  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      aggregationType: [''],
      dealerGroupName: [''],
      dealerState: [''],
      dealerId: [''],
      hasPolicy: [''],
      cpaPolicyCompany:['']
    });
    this.configDataService.PrintInstruction$.subscribe((x) => {
      // this.selectedPrintInstruction = x.filter(a => a.printOnlyFirstPage == true).map(x => x.policyType);
      this.selectedPrintInstruction = x.map(a => {
        a.aggregatePolicyType = a.aggregatePolicyType ?  a.aggregatePolicyType : "Others";
        if (this.columnDefs.findIndex(b => b.field == a.aggregatePolicyType) == -1)
        {
          this.columnDefs.push({ headerName: a.aggregatePolicyType, field: a.aggregatePolicyType, sortable: true, filter: true });
          this.aggregatePoilyTypes.push(a.aggregatePolicyType);
        }
        return a
      })
      this.gridApi.setGridOption( "columnDefs", this.columnDefs );
    });
    this.dealerService.getAllDealer().pipe(first()).subscribe({
      next: (data) => {
        this.dealerData = data;
      },
      error: (error) => {
        this.alertService.error('Error fetching dealers:', error);
      }
    });
    this.gridReady = true;

    this.certificateReportForm.get('issueDateFrom').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateFromSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });

      this.certificateReportForm.get('hasPolicy').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          if ((this.certificateReportForm.get('aggregationType').value == 'IssueDateHorizontal') && (this.OutputArray.length > 0))
          {
            if (value !='')
            {
              this.gridRowData = this.OutputArray.filter(a => a.hasPolicy == value);
            }
            else
            {
              this.gridRowData = this.OutputArray;

            }
          } 
        }
      });

    this.certificateReportForm.get('issueDateTo').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateToSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
        //console.log("issueDateToSearch: " + this.issueDateToSearch)
      });


  }
  // convenience getter for easy access to form fields
  get f() { return this.certificateReportForm.controls; }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    if (this.user.role != "admin" && this.user.role != "employee") {
      this.alertService.error("Unauthorized access");
      this.submitted = false;
      this.loading = false;
      return;
    }
    this.reportSearch = new ReportSearch();
    this.reportSearch.dealerGroupName = this.certificateReportForm.get('dealerGroupName').value;
    this.reportSearch.dealerState = this.certificateReportForm.get('dealerState').value;
    this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
    this.reportSearch.cpaPolicyCompany = this.certificateReportForm.get('cpaPolicyCompany').value;
    if (this.issueDateFromSearch == undefined) {
      this.reportSearch.issueDateFrom = "0001-01-01";
      // this.issueDateFromSearch = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);;
    }
    else
      this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined) {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
      this.reportSearch.issueDateTo = this.issueDateToSearch;
    if (this.certificateReportForm.get("aggregationType").value.length > 0)
    this.reportSearch.aggregationType = this.certificateReportForm.get("aggregationType").value;
    else this.reportSearch.aggregationType = "";

    // this.reportSearch.aggregationType = this.reportSearch.aggregationType.includes("IssueDate") ? "IssueDate" : this.reportSearch.aggregationType;

    this.searchService.getDealerSummaryReport(this.reportSearch)
      .pipe(first())
      .subscribe({
        next: (x) => {
            // console.log("searchg: " + JSON.stringify(x))
          if (this.reportSearch.cpaPolicyCompany == "IFCO Tokio")
          {
            x = x.filter(a => a.policyType.includes("Ifco"))
          }
          if (this.reportSearch.cpaPolicyCompany == "IFCO Tokio")
          {
            x = x.filter(a => a.policyType.includes("Ifco"))
          }
          if (this.reportSearch.cpaPolicyCompany == "None")
          {
            x = x.filter(a => this.selectedPrintInstruction.includes(a.policyType))
          }
          if (this.reportSearch.cpaPolicyCompany == "New India")
          {
            x = x.filter(a => !(this.selectedPrintInstruction.includes(a.policyType) || a.policyType.includes("Ifco")) )
          }
          
          if (Array.isArray(x)) {
            this.createGridFormatData(x);
          } else {
            this.alertService.error('Data format is incorrect');
          }
          // if (this.reportSearch.aggregationType == "IssueDate") { this.massageData(x); }
          // else { this.populateDealerData(x, []); }
          this.gridReady = true;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })

  }
  createGridFormatData(a: any[]) {
    this.resultTotal = 0;
    this.lineTotal = 0;
    this.OutputArray = a.map(element => {
      element[this.selectedPrintInstruction.find(a => a.policyType == element.policyType).aggregatePolicyType] = element.numberOfPolicySold;
      this.aggregatePoilyTypes.forEach(a => {
        element[a] = element[a] && element[a] > 0 ? element[a]:0;
      });
      element.totalPolicyNumber = element.numberOfPolicySold;
      this.resultTotal = this.resultTotal + element.totalPolicyNumber;
      element.key = element.dealerId + element.type + element.vehicleMake;
      element.dealerCity = this.dealerData.find(a => a.dealerId == element.dealerId).dealerCity;
      element.dealerState = this.dealerData.find(a => a.dealerId == element.dealerId).dealerState;
      return element
    }).sort((a,b) => (a.dealerId + a.type + a.vehicleMake )- (b.dealerId + a.type + b.vehicleMake));
    // console.log("OutputArray: " + JSON.stringify(this.OutputArray));
    this.gridRowData = [];
    var elementPlace = -1;
    
    this.OutputArray.forEach(element => {
      elementPlace = this.gridRowData.findIndex(a => a.key == element.key);
      if (elementPlace == -1) {
        this.gridRowData.push(element);
      }
      else {
        this.aggregatePoilyTypes.forEach(a => {
          this.gridRowData[elementPlace][a] = this.gridRowData[elementPlace][a] + element[a];
        });
        this.gridRowData[elementPlace].totalPolicyNumber = this.gridRowData[elementPlace].totalPolicyNumber + element.totalPolicyNumber;
      }
    }
    ); 
    this.gridRowData.push({dealerId:"Total",totalPolicyNumber:this.resultTotal});
    // console.log("gridRowData: " + JSON.stringify(this.gridRowData));   
    // const result = Object.values(aggregatedData).sort((a, b) => {
    //   const keyA = `${a.dealerId}-${a.type}-${a.vehicleMake}`;
    //   const keyB = `${b.dealerId}-${b.type}-${b.vehicleMake}`;
    //   return keyA.localeCompare(keyB);
    // });

  }
  onReset() {

  }
  exportToExcel() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'Dealer Summary Report - ' + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString(),
      //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.gridApi.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }

}
