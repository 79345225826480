import { Component, OnInit } from '@angular/core';
import { Certificate } from '@app/_models/certificate';
import { PrintInstruction } from '@app/_models/printInstruction';
import { AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';
import { ConfigDataService } from '@app/_services/config-data.service';

@Component({
  selector: 'app-general-cpa-policy',
  standalone: false,
  templateUrl: './general-cpa-policy.component.html',
  styleUrl: './general-cpa-policy.component.less'
})
export class GeneralCpaPolicyComponent {
  canSeePolicyCompanyLogo = false;
  selectedPrintInstruction: PrintInstruction = new PrintInstruction();
  certificate = new Certificate();
  constructor(private certificateService: CertificateService, private accountService:AccountService,private configDataService:ConfigDataService) { }

  ngOnInit(): void {
    this.certificate = new Certificate(this.certificateService.PrintCertificate);
    this.configDataService.getPrintInstructionList().subscribe((data) => {
      this.selectedPrintInstruction = data.find(x => x.policyType == this.certificate.policyType);
    }
  );
    this.canSeePolicyCompanyLogo =  (this.accountService.userValue.specialRoleAccess && this.accountService.userValue.specialRoleAccess.includes('canSeePolicyCompanyLogo'));
  }

}
