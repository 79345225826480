<h1 >Update Dealership Data</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerId">Dealer Id</label>
            <input type="text" formControlName="dealerId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
            <div *ngIf="submitted && f.dealerId.errors" class="invalid-feedback">
                <div *ngIf="f.dealerId.errors.required">Dealership Id is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerName">Dealership Name</label>
            <input type="text" formControlName="dealerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerName.errors }" />
            <div *ngIf="submitted && f.dealerName.errors" class="invalid-feedback">
                <div *ngIf="f.dealerName.errors.required">Dealership Name is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerGroupName">Group Name</label>
            <input type="text" formControlName="dealerGroupName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerGroupName.errors }" />
            <div *ngIf="submitted && f.dealerGroupName.errors" class="invalid-feedback">
                <div *ngIf="f.dealerGroupName.errors.required">Group Name is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerCreatePackage">Summary Page:</label>
            <select class="form-select" formControlName="dealerCreatePackage" [ngClass]="{ 'is-invalid': submitted && f.dealerCreatePackage.errors }">
                <option *ngFor="let selectedVal of canCreatePackageValues" [ngValue]="selectedVal">
                  {{ selectedVal }}
                </option>
              </select>
            <div *ngIf="submitted && f.dealerCreatePackage.errors" class="invalid-feedback">
                <div *ngIf="f.dealerCreatePackage.errors.required">Cab create Package choice is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="onePager">One Pager:</label>
            <select class="form-select" formControlName="onePager" [ngClass]="{ 'is-invalid': submitted && f.onePager.errors }">
                <option *ngFor="let selectVal of canCreatePackageValues" [ngValue]="selectVal">
                  {{ selectVal }}
                </option>
              </select>
            <div *ngIf="submitted && f.onePager.errors" class="invalid-feedback">
                <div *ngIf="f.onePager.errors.required">One Pager choice is required</div>
            </div>
        </div>
</div>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerAddress">Address</label>
            <input type="text" formControlName="dealerAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerAddress.errors }" />
            <div *ngIf="submitted && f.dealerAddress.errors" class="invalid-feedback">
                <div *ngIf="f.dealerAddress.errors.required">Dealership Address is required</div>
            </div>
        </div>
            <div class="form-group col">
                <label for="dealerState">State</label>
                <select class="form-select" formControlName="dealerState" [ngClass]="{ 'is-invalid': submitted && f.dealerState.errors }" >
                    <option *ngFor="let dealerState of stateCity" [ngValue]="dealerState.state">
                      {{ dealerState.state }}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.dealerState.errors" class="invalid-feedback">
                    <div *ngIf="f.dealerState.errors.required">State needs to be selected</div>
                </div>
            </div>
            <div class="form-group col">
                <label for="dealerCity">City</label>
                <select class="form-select" formControlName="dealerCity" [ngClass]="{ 'is-invalid': submitted && f.dealerCity.errors }">
                    <option *ngFor="let dealerCity of cities" [ngValue]="dealerCity">
                      {{ dealerCity }}
                    </option>
                  </select>
                <div *ngIf="submitted && f.dealerCity.errors" class="invalid-feedback">
                    <div *ngIf="f.dealerCity.errors.required">City is required</div>
                </div>
            </div>    
            <div class="form-group col">
                <label for="dealerPaymentShow">Dealer Payment Gateway Show</label>
                <select class="form-select" formControlName="dealerPaymentShow" [ngClass]="{ 'is-invalid': submitted && f.dealerPaymentShow.errors }">
                    <option *ngFor="let selectedPaymentValue of dealerPaymentShowValue" [ngValue]="selectedPaymentValue">
                      {{ selectedPaymentValue }}
                    </option>
                  </select>
                <div *ngIf="submitted && f.dealerPaymentShow.errors" class="invalid-feedback">
                    <div *ngIf="f.dealerPaymentShow.errors.required">Flag is required</div>
                </div>
            </div>    
        </div>

    <div class="form-row">
        <div class="form-group col">
            <label for="dealerContactNumber">Contact Number</label>
            <input type="number" formControlName="dealerContactNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerContactNumber.errors }" />
            <div *ngIf="submitted && f.dealerContactNumber.errors" class="invalid-feedback">
                <div *ngIf="f.dealerContactNumber.errors.required">dealerContactNumber is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerEmail">Email</label>
            <input type="text" formControlName="dealerEmail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerEmail.errors }" />
            <div *ngIf="submitted && f.dealerEmail.errors" class="invalid-feedback">
                <div *ngIf="f.dealerEmail.errors.required">Email is required</div>
                <div *ngIf="f.dealerEmail.errors.email">Email id is invalid</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerInvoiceShow">Dealer Invoice Show</label>
            <select class="form-select" formControlName="dealerInvoiceShow" [ngClass]="{ 'is-invalid': submitted && f.dealerInvoiceShow.errors }">
                <option *ngFor="let selectedValue of dealerInvoiceShowValue" [ngValue]="selectedValue">
                  {{ selectedValue }}
                </option>
              </select>
            <div *ngIf="submitted && f.dealerInvoiceShow.errors" class="invalid-feedback">
                <div *ngIf="f.dealerInvoiceShow.errors.required">Flag is required</div>
            </div>
        </div>    
        <div class="form-group col">
            <label for="invoiceType">Dealer Invoice Type</label>
            <select class="form-select" formControlName="invoiceType" [ngClass]="{ 'is-invalid': submitted && f.invoiceType.errors }">
                <option *ngFor="let selectedPaymentValue of ['Commission','Marketing','B2B']" [ngValue]="selectedPaymentValue">
                  {{ selectedPaymentValue }}
                </option>
              </select>
            <div *ngIf="submitted && f.invoiceType.errors" class="invalid-feedback">
                <div *ngIf="f.invoiceType.errors.required">Invoice Type is required</div>
            </div>
        </div>    

    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerMake">Make</label>
            <select multiple="multiple" class="form-control" formControlName="dealerMake" [ngClass]="{ 'is-invalid': submitted && f.dealerMake.errors }" [compareWith]="makeCompare">
                <option *ngFor="let dealerMake of makes" [ngValue]="dealerMake">
                  {{ dealerMake.make }}
                </option>
              </select>
              <div *ngIf="submitted && f.dealerMake.errors" class="invalid-feedback">
                <div *ngIf="f.dealerMake.errors.required">Make needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerPlanType">Plan</label>
            <select  class="form-select" formControlName="dealerPlanType" [ngClass]="{ 'is-invalid': submitted && f.dealerPlanType.errors }" >
                <option *ngFor="let planType of planTypes" [ngValue]="planType.id">
                 <div> {{ planType.text }} </div>
                </option>
              </select>
              <div *ngIf="submitted && f.dealerPlanType.errors" class="invalid-feedback">
                <div *ngIf="f.dealerPlanType.errors.required">Plan type needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerType">Dealer Type</label>
            <select  class="form-select" formControlName="dealerType" [ngClass]="{ 'is-invalid': submitted && f.dealerType.errors }" >
                <option *ngFor="let dealerType of dealerTypes" [ngValue]="dealerType">
                 <div> {{ dealerType }} </div>
                </option>
              </select>
              <div *ngIf="submitted && f.dealerType.errors" class="invalid-feedback">
                <div *ngIf="f.dealerType.errors.required">Dealer type needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerGst">GST</label>
            <select  class="form-select" formControlName="dealerGst" [ngClass]="{ 'is-invalid': submitted && f.dealerGst.errors }" >
                <option *ngFor="let dealerGst of ['Net', 'Full']" [ngValue]="dealerGst">
                 <div> {{ dealerGst }} </div>
                </option>
              </select>
              <div *ngIf="submitted && f.dealerGst.errors" class="invalid-feedback">
                <div *ngIf="f.dealerGst.errors.required">GST needs to be selected</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerBalance">CPA Balance</label>
            <input type="number" formControlName="dealerBalance" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBalance.errors }" />
            <div *ngIf="submitted && f.dealerBalance.errors" class="invalid-feedback">
                <div *ngIf="f.dealerBalance.errors.required">Dealer Balance is required</div>
            </div>
        </div>   
        <div class="form-group col">
            <label for="dealerBalanceLimit">CPA Balance Limit</label>
            <input type="number" formControlName="dealerBalanceLimit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBalanceLimit.errors }" />
            <div *ngIf="submitted && f.dealerBalanceLimit.errors" class="invalid-feedback">
                <div *ngIf="f.dealerBalanceLimit.errors.required">Dealer Balance Limit is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerPolicyBalance">Policy Balance</label>
            <input type="number" formControlName="dealerPolicyBalance" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerPolicyBalance.errors }" disabled/>
        </div>   
        <div class="form-group col">
            <label for="dealerPackageBalance">Package Balance</label>
            <input type="number" formControlName="dealerPackageBalance" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerPackageBalance.errors }" disabled/>
        </div>   
   
    </div>
    <!-- <div class="form-row">
        <div class="form-group col">
            <label for="dealerTotalPayment">Total CPA Business Done- Amount</label>
            <input type="number" formControlName="dealerTotalPayment" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalPayment.errors }" disabled/>
        </div>   
        <div class="form-group col">
            <label for="dealerTotalGstPayment">Total CPA Business Done- Taxes(GST)</label>
            <input type="number" formControlName="dealerTotalGstPayment" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalGstPayment.errors }" disabled/>
        </div>   
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerTotalRefund">Total CPA Refund Received- Amount</label>
            <input type="number" formControlName="dealerTotalRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalRefund.errors }" disabled/>
        </div>   
        <div class="form-group col">
            <label for="dealerTotalGstRefund">Total CPA Refund Received- Taxes(GST)</label>
            <input type="number" formControlName="dealerTotalGstRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalGstRefund.errors }" disabled/>
        </div>   
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerPendingRefund">Total CPA Pending Receivables- Amount</label>
            <input type="number" formControlName="dealerPendingRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerPendingRefund.errors }" disabled/>
        </div>   
        <div class="form-group col">
            <label for="dealerGstPendingRefund">Total CPA Pending Receivables- Taxes(GST)</label>
            <input type="number" formControlName="dealerGstPendingRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerGstPendingRefund.errors }" disabled/>
        </div>   
    </div> -->
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerBankAccountOwner">Account Owner Name</label>
            <input type="text" formControlName="dealerBankAccountOwner" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankAccountOwner.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerBankAccountNumber">Account Number</label>
            <input type="text" formControlName="dealerBankAccountNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankAccountNumber.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerBankIfscCode">IFSC code</label>
            <input type="text" formControlName="dealerBankIfscCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankIfscCode.errors }" />
        </div> 
    </div>  
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerBankOwnerPan">Receiver PAN number</label>
            <input type="text" formControlName="dealerBankOwnerPan" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankOwnerPan.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerGstId">Receiver GST number</label>
            <input type="text" formControlName="dealerGstId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerGstId.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerVpaId">Dealer VPA ID</label>
            <input type="text" formControlName="dealerVpaId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerVpaId.errors }" />
        </div>   
    </div>

    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <a routerLink="/users" class="btn btn-link">Cancel</a>
    </div>
</form>
<app-add-edit-plan-details></app-add-edit-plan-details>
<app-user-list *ngIf="!isAddMode"></app-user-list>
<app-add-payment *ngIf="!isAddMode"></app-add-payment>
<app-add-payout *ngIf="!isAddMode"></app-add-payout>
<app-package-payment *ngIf="!isAddMode"></app-package-payment>
