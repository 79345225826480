import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {PdfCertificateCreateComponent} from './pdf-certificate-create/pdf-certificate-create.component';
import {printRouting} from './print-routing.module';
import { PrintLayoutComponent } from './print-layout/print-layout.component';

import { PrintNewIndiaPolicyComponent } from './print-new-india-policy/print-new-india-policy.component';
import { RsaCertificateComponent } from './rsa-certificate/rsa-certificate.component';
import { NewRsaCertificateComponent } from './new-rsa-certificate/new-rsa-certificate.component';
import { RsaDocCertificateComponent } from './rsa-doc-certificate/rsa-doc-certificate.component';
import { CpaCertificatev2Component } from './cpa-certificatev2/cpa-certificatev2.component';
import { PrintAccidentPolicyComponent } from './print-accident-policy/print-accident-policy.component';
import { CpaDocPolicyComponent } from './cpa-doc-policy/cpa-doc-policy.component';
import { FinanceComponent } from './finance/finance.component';
import { PolicyFinanceComponent } from './policy-finance/policy-finance.component';
import { PrintNewIndiaCarPolicyComponent } from './print-new-india-car-policy/print-new-india-car-policy.component';
import { IffcoTokyoCpaPolicyComponent } from './iffco-tokyo-cpa-policy/iffco-tokyo-cpa-policy.component';
import { QRCodeModule } from 'angularx-qrcode';
import { RsaPvCertificateComponent } from './rsa-pv-certificate/rsa-pv-certificate.component';
import { RsaEvComponent } from './rsa-ev/rsa-ev.component';
import { GeneralCpaPolicyComponent } from './general-cpa-policy/general-cpa-policy.component';


@NgModule({
  declarations: [PdfCertificateCreateComponent, PrintLayoutComponent, PrintNewIndiaPolicyComponent, RsaCertificateComponent, NewRsaCertificateComponent, RsaDocCertificateComponent, CpaCertificatev2Component, PrintAccidentPolicyComponent, CpaDocPolicyComponent, FinanceComponent, PolicyFinanceComponent, PrintNewIndiaCarPolicyComponent, IffcoTokyoCpaPolicyComponent, RsaPvCertificateComponent, RsaEvComponent,GeneralCpaPolicyComponent],
  imports: [
    CommonModule,
    printRouting,
    QRCodeModule

  ],
  exports: [PdfCertificateCreateComponent],
  bootstrap: [PdfCertificateCreateComponent],
  providers: [DatePipe]

})
export class PrintModule { }
